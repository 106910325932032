<template>
  <div class="home app-content">
    <ReportParams></ReportParams>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ReportParams from "@/components/ReportParams.vue"; // @ is an alias to /src

@Options({
  components: {
    ReportParams,
  },
})
export default class Home extends Vue {}
</script>
