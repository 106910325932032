export const reports = [
  {
    id: 985974,
    name: "_skuska_parametrov-string.rptdesign",
  },
  {
    id: 986079,
    name: "_skuska_parametrov-integer.rptdesign",
  },
  {
    id: 987372,
    name: "_skuska_parametrov-decimal.rptdesign",
  },
  {
    id: 987466,
    name: "_skuska_parametrov-float.rptdesign",
  },
  {
    id: 986126,
    name: "_skuska_parametrov-boolean.rptdesign",
  },
  {
    id: 986228,
    name: "_skuska_parametrov-date.rptdesign",
  },
  {
    id: 987241,
    name: "_skuska_parametrov-time.rptdesign",
  },
  {
    id: 986883,
    name: "_skuska_parametrov-datetime.rptdesign",
  },
  {
    id: 985578,
    name: "_skuska_parametrov.rptdesign",
  },
  {
    id: 673220,
    name: "_zoznam_zakaznikov.rptdesign",
  },
  {
    id: 673258,
    name: "_objednavky_zakaznika.rptdesign",
  },
];
