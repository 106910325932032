<template>
  <div>
    <h1 class="govuk-heading-xl">Report Parameters</h1>

    <div>
      <div class="govuk-form-group">
        <label class="govuk-label" for="rs-url">RS URL</label>
        <input
          class="govuk-input"
          type="text"
          name="rs-url"
          id="rs-url"
          v-model="RS_URL"
        />
      </div>
      <div class="govuk-form-group">
        <label class="govuk-label" for="rs-user">RS user</label>
        <input
          class="govuk-input"
          type="text"
          name="rs-user"
          id="rs-user"
          v-model="RS_USER"
        />
      </div>
      <div class="govuk-form-group">
        <label class="govuk-label" for="rs-apikey">RS API key</label>
        <input
          class="govuk-input"
          type="text"
          name="rs-apikey"
          id="rs-apikey"
          v-model="RS_APIKEY"
        />
      </div>
      <div class="govuk-form-group">
        <label class="govuk-label" for="rs-format">RS format</label>
        <input
          class="govuk-input"
          type="text"
          name="rs-format"
          id="rs-format"
          v-model="format"
        />
      </div>
      <div class="govuk-form-group">
        <label class="govuk-label" for="rs-download">RS download</label>
        <input
          class="govuk-input"
          type="text"
          name="rs-download"
          id="rs-download"
          v-model="download"
        />
      </div>
      <div class="govuk-form-group">
        <label class="govuk-label" for="rs-reportId">RS report id</label>
        <input
          class="govuk-input"
          type="text"
          name="rs-reportId"
          id="rs-reportId"
          v-model="reportId"
        />
      </div>
    </div>

    <div class="govuk-grid-row">
      <div class="govuk-grid-column-one-half">
        <div class="govuk-form-group">
          <label class="govuk-label" for="file-upload-1">
            Select report file
          </label>
          <input
            class="govuk-file-upload"
            id="file-upload-1"
            name="file-upload-1"
            type="file"
            @change="uploadFile"
          />
          <div>
            <p>{{ message }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="params.length">
      <table class="govuk-table">
        <thead class="govuk-table__head">
          <tr class="govuk-table__row">
            <th scope="col" class="govuk-table__header">Value</th>
            <th scope="col" class="govuk-table__header">Help Text</th>
            <th scope="col" class="govuk-table__header">Name (Type)</th>
          </tr>
        </thead>
        <tbody class="govuk-table__body">
          <tr class="govuk-table__row" v-for="p in params" :key="p.name">
            <td class="govuk-table__cell">
              <label :for="p.name">{{ p.promptText }}</label>
              <input
                :id="p.name"
                :type="getInputType(p)"
                v-model="p.value"
                class="govuk-input"
                v-if="p.type !== 'boolean'"
              />

              <div class="govuk-checkboxes__item" v-if="p.type === 'boolean'">
                <input
                  :id="p.name"
                  :type="getInputType(p)"
                  v-model="p.value"
                  class="govuk-checkboxes__input"
                />
                <label
                  class="govuk-label govuk-checkboxes__label"
                  :for="p.name"
                ></label>
              </div>
            </td>
            <td class="govuk-table__cell">{{ p.helpText }}</td>
            <td class="govuk-table__cell">{{ `${p.name} (${p.type})` }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-if="!params.length | (params.length === 0)" class="govuk-form-group">
    No Report Parameters found
  </div>

  <div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
      <div class="govuk-form-group">
        <div class="govuk-label">
          {{ requestString }}
        </div>
        <div>
          <button
            @click="sendRequest"
            class="idsk-button"
            data-module="idsk-button"
          >
            SEND
          </button>
          <div>{{ response }}</div>
        </div>
      </div>

      <div class="govuk-form-group">
        <label class="govuk-label" for="show-xml">Show XML</label>
        <div class="govuk-checkboxes">
          <div class="govuk-checkboxes__item">
            <input
              type="checkbox"
              name="show-xml"
              id="show-xml"
              v-model="showXml"
              class="govuk-checkboxes__input"
            />
            <label
              class="govuk-label govuk-checkboxes__label"
              for="show-xml"
            ></label>
          </div>
        </div>
      </div>

      <div class="govuk-form-group">
        <pre v-if="showXml">
        {{ uploadedFile }}
      </pre
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { IParam } from "@/types/Param";
import axios from "axios";
import moment from "moment";
import { reports } from "@/reports";
import LogRocket from "logrocket";

LogRocket.init("gidavy/rsv-sample-dev");
LogRocket.identify("USER123", {
  name: "Rastislav Svoboda",
  email: "jamesmorrison@example.com",

  // Add your own custom user variables here, ie:
  subscriptionType: "poor man",
});

@Options({
  name: "ReportParams",
})
export default class ReportParams extends Vue {
  params: IParam[] = [];
  showXml = false;
  uploadedFile: string | ArrayBuffer | null = null;
  message = "";
  reportId = "0";
  response = "";

  RS_URL = process.env.VUE_APP_RS_URL;
  RS_USER = process.env.VUE_APP_RS_USER;
  RS_APIKEY = process.env.VUE_APP_RS_APIKEY;

  format = "pdf";
  download = true;

  parseParameters(xml: any): IParam[] {
    let results: IParam[] = [];

    let domParser = new DOMParser();
    let xmlDocument = domParser.parseFromString(xml, "text/xml");
    let parameters = xmlDocument.getElementsByTagName("parameters");

    let myHash: any = {};

    for (let p of parameters) {
      let scalarParameters = p.getElementsByTagName("scalar-parameter");
      for (let scalarP of scalarParameters) {
        let name = scalarP.getAttribute("name");
        let id = scalarP.getAttribute("id");
        if (id) {
          let item: IParam = {
            name: "",
            type: "",
            promptText: null,
            helpText: null,
            value: "",
          };

          let properties = scalarP.getElementsByTagName("property");
          for (let prop of properties) {
            let pname = prop.getAttribute("name");
            if (pname === "dataType") {
              item.name = name as string;
              item.type = prop.textContent as string;
            }
          }

          let textProperties = scalarP.getElementsByTagName("text-property");
          for (let prop of textProperties) {
            let pname = prop.getAttribute("name");
            if (pname === "promptText") {
              item.promptText = prop.textContent as string;
            } else if (pname === "helpText") {
              item.helpText = prop.textContent as string;
            }
          }
          myHash[id] = item;
        }
      }
    }

    for (let id in myHash) {
      results.push(myHash[id]);
    }

    LogRocket.log(`parsed ${myHash} parameters`);

    return results;
  }

  getInputType(param: IParam): string | null {
    if (param.type === "string") return "text";
    if (param.type === "integer") return "number";
    if (param.type === "decimal") return "number";
    if (param.type === "float") return "number";
    if (param.type === "boolean") return "checkbox";
    if (param.type === "date") return "date";
    if (param.type === "time") return "time";
    if (param.type === "dateTime") return "string";

    alert("unknown parameter type " + param.type);

    return null;
  }

  getSerializedValue(param: IParam): string {
    if (param.type === "string") return param.value;

    // TODO: cisla by asi mali byt bez medzier a roznych separatorov
    if (param.type === "integer") return param.value;
    if (param.type === "decimal") return param.value;
    if (param.type === "float") return param.value;

    if (param.type === "boolean") return param.value;

    // TODO: datumy by mali mat lokalnu zonu
    if (param.type === "date") {
      const dateVal = new Date(param.value);
      return moment(dateVal).format("YYYY-MM-DDT00:00:00-00:00");
    }

    if (param.type === "time") {
      console.log(":: time ::", param.value);
      // TODO: sekundy a zona stale 0?
      const normalized = "0001-01-01T" + param.value.toString() + ":00-00:00";
      console.log("norm: ", normalized);
      return normalized;
    }

    if (param.type === "dateTime") {
      const dateVal = new Date(param.value);
      return moment(dateVal).format("YYYY-MM-DDTHH:MM:SS-00:00");
    }

    alert("unknown parameter type " + param.type);

    return "";
  }

  uploadFile(event: any) {
    const reader = new FileReader();
    reader.onload = () => {
      const xml = reader.result;
      this.uploadedFile = xml;
      this.params = this.parseParameters(xml);

      // ----------
      // NOTE: len docasna funkcionalita, ktora najde report id podla nazvu suboru
      const fileName = event.target.files[0].name;
      const report = reports.find((x) => x.name === fileName);
      if (report) {
        this.reportId = report.id.toString();
      }
      // ----------
    };
    reader.onerror = () => {
      this.message =
        "There was a problem uploading your image, please try again";
    };
    reader.readAsText(event.target.files[0]);
  }

  get requestString() {
    return `${this.RS_URL}?${this.requestParams}`;
  }

  get requestParams() {
    const searchParams = new URLSearchParams();
    searchParams.append("user", this.RS_USER);
    searchParams.append("apikey", this.RS_APIKEY);
    searchParams.append("format", this.format);
    searchParams.append("download", this.download.toString());
    searchParams.append("id", this.reportId);

    for (let p of this.params) {
      searchParams.append(`p_${p.name}`, this.getSerializedValue(p));
    }

    return searchParams;
  }

  async sendRequest() {
    try {
      const url = this.requestString;
      let result = await axios.get(url);
      console.log(result);
      this.response = result.status.toString();
      return result.data;
    } catch (error) {
      this.response = error;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/global.scss";
</style>
