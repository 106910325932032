<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>

<style lang="scss">
@import "@/scss/global.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;

  .app-menu {
    position: fixed;
  }

  .app-content {
    padding: 1.2rem;
    width: 90%;
    margin: 1rem auto 1rem $menu-width;
    text-align: left;
  }
}

* {
  margin: 0;
}

a {
  text-decoration: none;
}

hr {
  border: 1px solid #eee;
  margin: 1rem 0;
}
</style>

<style src="@/assets/id-sk-frontend/idsk-frontend.min.css"></style>
