
import { Options, Vue } from "vue-class-component";
import { IParam } from "@/types/Param";
import axios from "axios";
import moment from "moment";
import { reports } from "@/reports";
import LogRocket from "logrocket";

LogRocket.init("gidavy/rsv-sample-dev");
LogRocket.identify("USER123", {
  name: "Rastislav Svoboda",
  email: "jamesmorrison@example.com",

  // Add your own custom user variables here, ie:
  subscriptionType: "poor man",
});

@Options({
  name: "ReportParams",
})
export default class ReportParams extends Vue {
  params: IParam[] = [];
  showXml = false;
  uploadedFile: string | ArrayBuffer | null = null;
  message = "";
  reportId = "0";
  response = "";

  RS_URL = process.env.VUE_APP_RS_URL;
  RS_USER = process.env.VUE_APP_RS_USER;
  RS_APIKEY = process.env.VUE_APP_RS_APIKEY;

  format = "pdf";
  download = true;

  parseParameters(xml: any): IParam[] {
    let results: IParam[] = [];

    let domParser = new DOMParser();
    let xmlDocument = domParser.parseFromString(xml, "text/xml");
    let parameters = xmlDocument.getElementsByTagName("parameters");

    let myHash: any = {};

    for (let p of parameters) {
      let scalarParameters = p.getElementsByTagName("scalar-parameter");
      for (let scalarP of scalarParameters) {
        let name = scalarP.getAttribute("name");
        let id = scalarP.getAttribute("id");
        if (id) {
          let item: IParam = {
            name: "",
            type: "",
            promptText: null,
            helpText: null,
            value: "",
          };

          let properties = scalarP.getElementsByTagName("property");
          for (let prop of properties) {
            let pname = prop.getAttribute("name");
            if (pname === "dataType") {
              item.name = name as string;
              item.type = prop.textContent as string;
            }
          }

          let textProperties = scalarP.getElementsByTagName("text-property");
          for (let prop of textProperties) {
            let pname = prop.getAttribute("name");
            if (pname === "promptText") {
              item.promptText = prop.textContent as string;
            } else if (pname === "helpText") {
              item.helpText = prop.textContent as string;
            }
          }
          myHash[id] = item;
        }
      }
    }

    for (let id in myHash) {
      results.push(myHash[id]);
    }

    LogRocket.log(`parsed ${myHash} parameters`);

    return results;
  }

  getInputType(param: IParam): string | null {
    if (param.type === "string") return "text";
    if (param.type === "integer") return "number";
    if (param.type === "decimal") return "number";
    if (param.type === "float") return "number";
    if (param.type === "boolean") return "checkbox";
    if (param.type === "date") return "date";
    if (param.type === "time") return "time";
    if (param.type === "dateTime") return "string";

    alert("unknown parameter type " + param.type);

    return null;
  }

  getSerializedValue(param: IParam): string {
    if (param.type === "string") return param.value;

    // TODO: cisla by asi mali byt bez medzier a roznych separatorov
    if (param.type === "integer") return param.value;
    if (param.type === "decimal") return param.value;
    if (param.type === "float") return param.value;

    if (param.type === "boolean") return param.value;

    // TODO: datumy by mali mat lokalnu zonu
    if (param.type === "date") {
      const dateVal = new Date(param.value);
      return moment(dateVal).format("YYYY-MM-DDT00:00:00-00:00");
    }

    if (param.type === "time") {
      console.log(":: time ::", param.value);
      // TODO: sekundy a zona stale 0?
      const normalized = "0001-01-01T" + param.value.toString() + ":00-00:00";
      console.log("norm: ", normalized);
      return normalized;
    }

    if (param.type === "dateTime") {
      const dateVal = new Date(param.value);
      return moment(dateVal).format("YYYY-MM-DDTHH:MM:SS-00:00");
    }

    alert("unknown parameter type " + param.type);

    return "";
  }

  uploadFile(event: any) {
    const reader = new FileReader();
    reader.onload = () => {
      const xml = reader.result;
      this.uploadedFile = xml;
      this.params = this.parseParameters(xml);

      // ----------
      // NOTE: len docasna funkcionalita, ktora najde report id podla nazvu suboru
      const fileName = event.target.files[0].name;
      const report = reports.find((x) => x.name === fileName);
      if (report) {
        this.reportId = report.id.toString();
      }
      // ----------
    };
    reader.onerror = () => {
      this.message =
        "There was a problem uploading your image, please try again";
    };
    reader.readAsText(event.target.files[0]);
  }

  get requestString() {
    return `${this.RS_URL}?${this.requestParams}`;
  }

  get requestParams() {
    const searchParams = new URLSearchParams();
    searchParams.append("user", this.RS_USER);
    searchParams.append("apikey", this.RS_APIKEY);
    searchParams.append("format", this.format);
    searchParams.append("download", this.download.toString());
    searchParams.append("id", this.reportId);

    for (let p of this.params) {
      searchParams.append(`p_${p.name}`, this.getSerializedValue(p));
    }

    return searchParams;
  }

  async sendRequest() {
    try {
      const url = this.requestString;
      let result = await axios.get(url);
      console.log(result);
      this.response = result.status.toString();
      return result.data;
    } catch (error) {
      this.response = error;
    }
  }
}
