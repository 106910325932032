
import { Options, Vue } from "vue-class-component";
import ReportParams from "@/components/ReportParams.vue"; // @ is an alias to /src

@Options({
  components: {
    ReportParams,
  },
})
export default class Home extends Vue {}
